import React, { Component } from "react";
import "materialize-css";
import M from "materialize-css";
import "../CSS/products.css";
import rp from "request-promise";

class Matilda extends Component {
  state = {
  fullCatalouge:"",
  surgicalGowns:"",
  opthamologyPacks:"",
  generalSurgery:"",
  orthopedicPacks:"",
  deliveryPacks:"",
  surgicalDrapes:"",
  protectiveApparel:""
};
  componentDidMount() {
    document.addEventListener("DOMContentLoaded", function () {
      var elems = document.querySelectorAll(".tooltipped");
      M.Tooltip.init(elems, {
        inDuration: 50,
        exitDelay: 50,
        position: "left", 
      });
    });

    const requestOptions = {
      uri: "https://centra-egypt.com/api/catalouges",
      // uri: "http://localhost:4000/api/catalouges",
      method: "GET",
      json: true
    }; 
    rp(requestOptions)
    .then((data) => {
      console.log(data)
      // this.state = data
      this.setState(data)
      console.log("State",this.state)
    })
    .catch(error => console.log(error));
  }
  render() {
    //Responsive Version
    const style = {
      border: "none",
      paddingTop: "3px",
    };
    const url = this.state
    return (
      <div class="container">
        <div className="left-align row">
          <h3
            style={{
              margin: "1rem 0 1.4rem 0",
              textAlign: "center",
              color: "#1a237e",
              fontWeight: "bold",
            }}
          >
            OUR PRODUCTS
          </h3>
          <div className="col s12 m6 l6 xl6">
            <ul class="collection with-header clearBorder">
              <li class="collection-header">
                <h5 className="headerStyle">
                  Surgical Gowns
                  <a
                    href={url.surgicalGowns}
                    class="secondary-content iconContainer"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      class="material-icons iconStyle tooltipped"
                      data-tooltip="View Surgical Gowns"
                    >
                      file_download
                    </i>
                  </a>
                </h5>
              </li>
              <li class="collection-item bodyStyle">Surgical Gowns</li>
            </ul>
          </div>

          <div className="col s12 m6 l6 xl6">
            <ul class="collection with-header clearBorder">
              <li class="collection-header">
                <h5 className="headerStyle">
                  Opthamology Packs
                  <a
                    href={url.opthamologyPacks}
                    class="secondary-content iconContainer"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      class="material-icons iconStyle tooltipped"
                      data-tooltip="View Opthamology Packs"
                    >
                      file_download
                    </i>
                  </a>
                </h5>
              </li>
              <li class="collection-item bodyStyle">Eye Packs</li>
            </ul>
          </div>
        </div>

        <div className="left-align row">
          <div className="col s12 m6 l6 xl6">
            <ul class="collection with-header clearBorder">
              <li class="collection-header">
                <h5 class="headerStyle">
                  General Surgery Packs
                  <a
                    href={url.generalSurgery}
                    class="secondary-content iconContainer"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      class="material-icons iconStyle tooltipped"
                      data-tooltip="View General Surgery Packs"
                    >
                      file_download
                    </i>
                  </a>
                </h5>
              </li>
              <div className="bodyStyle">
                <li class="collection-item" style={style}>
                  General Pack
                </li>
                <li class="collection-item" style={style}>
                  Liver Transplant Surgery Pack
                </li>
                <li class="collection-item" style={style}>
                  Cardiovascular Surgery Pack
                </li>
                <li class="collection-item" style={style}>
                  Lap Incise Pack
                </li>
                <li class="collection-item" style={style}>
                  ENT Pack
                </li>
                <li class="collection-item" style={style}>
                  Urology Pack
                </li>
              </div>
            </ul>
          </div>

          <div className="col s12 m6 l6 xl6">
            <ul class="collection with-header clearBorder">
              <li class="collection-header">
                <h5 className="headerStyle">
                  Delivery Packs
                  <a
                    href={url.deliveryPacks}
                    class="secondary-content iconContainer"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      class="material-icons iconStyle tooltipped"
                      data-tooltip="View Delivery Packs"
                    >
                      file_download
                    </i>
                  </a>
                </h5>
              </li>
              <div className="bodyStyle">
                <li class="collection-item" style={style}>
                  Cesarean Pack
                </li>
                <li class="collection-item" style={style}>
                  Delivery Pack
                </li>
              </div>
            </ul>
          </div>
        </div>

        <div className="left-align row">
          <div className="col s12 m6 l6 xl6">
            <ul class="collection with-header clearBorder">
              <li class="collection-header">
                <h5 className="headerStyle">
                  Orthopedic Packs
                  <a
                    href={url.orthopedicPacks}
                    class="secondary-content iconContainer"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      class="material-icons iconStyle tooltipped"
                      data-tooltip="View Orthopedic Surgery Packs"
                    >
                      file_download
                    </i>
                  </a>
                </h5>
              </li>
              <div className="bodyStyle">
                <li class="collection-item" style={style}>
                  Joints Replacment Surgery
                </li>
                <li class="collection-item" style={style}>
                  Arthroscopy Surgery
                </li>
                <li class="collection-item" style={style}>
                  Laminectomy Surgery
                </li>
                <li class="collection-item" style={style}>
                  Spinal Surgery
                </li>
                <li class="collection-item" style={style}>
                  Lap Incise Pack
                </li>
              </div>
            </ul>
          </div>

          <div className="col s12 m6 l6 xl6">
            <ul class="collection with-header clearBorder">
              <li class="collection-header">
                <h5 className="headerStyle">
                  Surgical Drapes
                  <a
                    href={url.surgicalDrapes}
                    class="secondary-content iconContainer"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      class="material-icons iconStyle tooltipped"
                      data-tooltip="View Surgical Drapes"
                    >
                      file_download
                    </i>
                  </a>
                </h5>
              </li>
              <div className="bodyStyle">
                <li class="collection-item" style={style}>
                  Surgical Drapes
                </li>
                <li class="collection-item" style={style}>
                  General Drapes
                </li>
                <li class="collection-item" style={style}>
                  Other Components
                </li>
              </div>
            </ul>
          </div>
        </div>

        <div className="left-align row">
          <div className="col s12 m6 l6 xl6">
            <ul class="collection with-header clearBorder">
              <li class="collection-header">
                <h5 className="headerStyle">
                  Protective Apparel
                  <a
                    href={url.protectiveApparel}
                    class="secondary-content iconContainer"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      class="material-icons iconStyle tooltipped"
                      data-tooltip="View Protective Apparel"
                    >
                      file_download
                    </i>
                  </a>
                </h5>
              </li>
              <div className="bodyStyle">
                <li class="collection-item" style={style}>
                  Patient Groups
                </li>
                <li class="collection-item" style={style}>
                  Isolation Gown
                </li>
                <li class="collection-item" style={style}>
                  Head & Foot Wear
                </li>
                <li class="collection-item" style={style}>
                  CSR Wrap
                </li>
                <li class="collection-item" style={style}>
                  Other Apparel
                </li>
              </div>
            </ul>
          </div>

          <div className="col s12 m6 l6 xl6">
            <ul class="collection with-header clearBorder">
              <li class="collection" style={style}>
                <div className="headerStyle center-align">
                  <h5>
                    The Full Catalouge is Available for
                    <a
                      href={url.fullCatalouge}
                      class="secondary-content iconContainer"
                      target="_blank"
                      style={{ float: "inherit" }}
                      rel="noopener noreferrer"
                    >
                      {" "}
                      Download Here.
                    </a>
                  </h5>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Matilda;
