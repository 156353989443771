import React, { Component } from "react";
import "materialize-css";
import "../CSS/errorPage.css"

class Matilda extends Component {
  state = { width: -1, height: -1, slideIndex: 1, slides: [], dots: [] };


 
  render() {
    //Desktop Version
    return (
      <div>
        <div className="row">
          <div style={{ padding: "50px" }} className="col s12 m12 l12 xl12">
            <h4 className="flow-text">OOPS! PAGE NOT FOUND</h4>
            <h2 className="err404">404</h2>
            <h5 className="flow-text">
              WE ARE SORRY, BUT THE PAGE YOU <br />
              REQUESTED WAS NOT FOUND!
            </h5>
          </div>
        </div>
      </div>
    );
  }
}

export default Matilda;
