import React from "react";
import "./App.css";
import { Route, Switch, BrowserRouter } from 'react-router-dom'; import 'materialize-css'
import './CSS/tweaks.css'
import Footer from './Components/Footer'
import NavBar from './Components/NavBar'

import Parallax from './Components/Landing'
import E404 from './Components/Error'
import Products from "./Components/Products"
import Cerficates from "./Components/Certficates"
import Email from "./Components/EmailPage"


function App() {
  return (
    <div className="App">
      <div>
        <NavBar></NavBar>
        <BrowserRouter>
          <Switch>
            <Route exact path="/">
              <Parallax />
            </Route>
            <Route exact path="/products">
              <Products />
            </Route>
            <Route exact path="/cerficates">
              <Cerficates />
            </Route>
            <Route exact path="/contact">
              <Email />
            </Route>
            <Route path="*">
              <E404 />
            </Route>
          </Switch>
        </BrowserRouter>
        <Footer />
      </div>
    </div>
  );
}

export default App;
