import React, { Component } from "react";
import "materialize-css";
import Email from "./Email";
class Matilda extends Component {
  state = { width: -1, height: -1, slideIndex: 1, slides: [], dots: [] };

  render() {
    //Desktop Version
    return (
      <div>
        <br />
        <br />
        <br />
        <Email />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default Matilda;
