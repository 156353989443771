import React, { Component } from "react";
import "materialize-css";
import M from "materialize-css";
import "../CSS/products.css"
import CE from "../media/icons/CE.png";
import EGY from "../media/icons/mie.png";
import ISO from "../media/icons/ISO.png";
class Matilda extends Component {
  state = { width: -1, height: -1, slideIndex: 1, slides: [], dots: [] };
  componentDidMount() {
    document.addEventListener("DOMContentLoaded", function () {
      var elems = document.querySelectorAll(".tooltipped");
      M.Tooltip.init(elems, {
        inDuration: 50,
        exitDelay: 50,
        position: "left",
      });
    });
  }
  render() {
    //Desktop Version
    //https://drive.google.com/file/d//view?usp=sharing
    const cert = {
      iso: "https://drive.google.com/uc?export=view&id=1MtDveH3IVufG8YeJUevVEkIy_N33q_1S",
      ce: "https://drive.google.com/uc?export=view&id=1TVR-Qan0rhXUQRtAUd2yrd6faqRULu-5",
      egy: "https://drive.google.com/uc?export=view&id=1ft_OfUJ3Qrnt5vYiap0w2Q5u-5zSF-PJ",
    };
    return (
      <div className="container">
        <div className="row">
          <h3
            style={{
              margin: "1rem 0 1.4rem 0",
              textAlign: "center",
              color: "#1a237e",
              fontWeight: "bold",
            }}
          >
            CENTRA ACHIEVED THE FOLLOWING CERTFICATES
          </h3>
        </div>
        <br />
        <br />
        <div className="left-align row">
          <div className="col s0 m2 l2 xl2" />
          <div className="col s12 m8 l8 xl8">
            <ul class="collection">
              <li class="collection-item avatar">
                <img src={CE} alt="" class="circle" />
                <span class="title">EC Certficate</span>
                <p>
                  TUV <br />
                </p>
                <a
                  href={cert.ce}
                  target="_blank"
                  rel="noopener noreferrer"
                  class="secondary-content"
                >
                  <i
                    data-position="left"
                    data-tooltip="View Certficate"
                    class="material-icons iconContainer displayRuby tooltipped"
                  >
                    file_download
                  </i>
                </a>
              </li>
              <li class="collection-item avatar">
                <img src={ISO} alt="" class="circle" />
                <span class="title">EN ISO 13485:2016</span>
                <p>TUV</p>
                <a
                  href={cert.iso}
                  target="_blank"
                  rel="noopener noreferrer"
                  class="secondary-content"
                >
                  <i
                    data-position="left"
                    data-tooltip="View Certficate"
                    class="material-icons iconContainer displayRuby tooltipped"
                  >
                    file_download
                  </i>
                </a>
              </li>
              <li class="collection-item avatar">
                <img src={EGY} alt="" class="circle" />
                <span class="title">Made In Egypt</span>
                <p>Egyptian Goverment</p>
                <a
                  href={cert.egy}
                  target="_blank"
                  rel="noopener noreferrer"
                  class="secondary-content"
                >
                  <i
                    data-position="left"
                    data-tooltip="View Certficate"
                    class="material-icons iconContainer displayRuby tooltipped"
                  >
                    file_download
                  </i>
                </a>
              </li>
            </ul>
          </div>
          <div className="col s0 m2 l2 xl2" />
        </div>
        <br />
        <br />
        <div className="row"></div>
        <div className="row"></div>
      </div>
    );
  }
}

export default Matilda;
//offset-s6
